.overlay {

  min-width: 100vw;
  min-height: 100vh;

  display: flex;
  align-items: center;
  position: fixed;
  background-color: rgba(0, 0, 0, 0.4);

  .content-container {
    padding: 20px;
    animation-duration: 2.3s;

    text-align: center;

    @media only screen and (max-width: 460px) {
      margin-top: 1rem;
      height: 100vh;
    }

    .close-button-container {
      margin: 20px;
      color: rgba(255, 255, 255, 0.5);

      @media only screen and (max-width: 860px) {
        margin-bottom: 10px;
      }

      @media only screen and (max-width: 460px) {
        margin: 0px;
      }

      .close-icon {
        cursor: pointer;
        font-size: 3rem;
        animation-duration: 2.3s;
        width: auto;
        height: auto;

        &:hover {
          color: rgba(255, 255, 255, 0.72);
        }

        @media only screen and (max-width: 860px) {
          font-size: 2.5rem;
        }

        @media only screen and (max-width: 400px) {
          font-size: 1.5rem;
        }
      }

      .close-text {
        margin-top: 0.8rem;
        display: block;
        font-size: 1.2rem;

        @media only screen and (max-width: 860px) {
          font-size: 1rem;
          margin-top: 0rem;
        }

        @media only screen and (max-width: 400px) {
          font-size: 0.8rem;
          margin-top: 0rem;
        }
      }
    }

    .header.title {
      color: white;
      margin-bottom: 10px;

      @media only screen and (max-width: 860px) {
        margin: 0px 0px 5px 0px;

        h1 {
          font-size: 2rem;
        }
      }

      @media only screen and (max-width: 400px) {
        margin: 5px 0px 5px 0px;

        h1 {
          font-size: 1.2rem;
        }
      }
    }

    .intro {
      color: white;
    }
  }
}