#home {
  // background-image: url('../images/original-red-dress-dotted.jpg');
  background-image: url('../images/red-dress-dotted-low-exposure.jpeg');
  width: 100vw;
  height: 100vh;
  background-size: cover;

  .home-overlay {
    // background-image: url('../images/pattern.png');
    width: 100%;
    height: 100%;
    // background-size: 0.4rem;

    // Content Alignment
    display: flex;
    align-items: center;
    justify-content: center;

    background-color: rgba(0, 0, 0, 0.2);

    #navbar {
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: center;

      .about,
      .media,
      .contact {
        margin: 0 3.8rem;
        animation-duration: 2.5s;

        @media only screen and (max-width: 800px) {
          margin: 0 2rem;
        }

        @media only screen and (max-width: 400px) {
          margin: 0 1rem;
        }
      }
    }
  }
}